@tailwind base;
@tailwind components;
@tailwind utilities;

/* poppins */

@font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Light.ttf);                
    font-weight: 300;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Regular.ttf);                
    font-weight: 400;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Medium.ttf);                
    font-weight: 500;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-SemiBold.ttf);                
    font-weight: 600;
  }
  
  @font-face {
    font-family: poppins;
    src: url(../public/fonts/poppins/Poppins-Bold.ttf);                
    font-weight: 700;
  }
  
  .poppins {
    font-family: poppins;
  }
  
  /* inter */
  
  @font-face {
    font-family: inter;
    src: url(../public/fonts/inter/Inter-Light.ttf);                
    font-weight: 300;
  }
  
  @font-face {
    font-family: inter;
    src: url(../public/fonts/inter/Inter-Regular.ttf);                
    font-weight: 400;
  }
  
  @font-face {
    font-family: inter;
    src: url(../public/fonts/inter/Inter-Medium.ttf);                
    font-weight: 500;
  }
  
  @font-face {
    font-family: inter;
    src: url(../public/fonts/inter/Inter-SemiBold.ttf);                
    font-weight: 600;
  }
  
  @font-face {
    font-family: inter;
    src: url(../public/fonts/inter/Inter-Bold.ttf);                
    font-weight: 700;
  }
  
  .inter {
    font-family: inter;
  }
  

@layer base {
    html {
        font-family: "inter", system-ui, sans-serif;
    }
    h1 {
        font-size: 40px;
        line-height: 48px;
        font-family: "poppins", system-ui, sans-serif;
    }
    h2 {
        font-size: 32px;
        line-height: 40px;
        font-family: "poppins", system-ui, sans-serif;
    } 
    h3 {
        font-size: 24px;
        line-height: 28px;
        font-family: "poppins", system-ui, sans-serif;
    } 
    h4 {
        font-size: 20px;
        line-height: 24px;
        font-family: "poppins", system-ui, sans-serif;
    } 
    h5 {
        font-size: 16px;
        line-height: 20px;
        font-family: "poppins", system-ui, sans-serif;
    } 
}

html {
  background-color: #0B0B0B;
}

img, svg {
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
}

.instrument-main-bg {
  background: linear-gradient(100.7deg, rgba(27, 27, 27, 0.7) 0%, rgba(12, 12, 12, 0.7) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));  
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.instrument-nonactivate-bg {
  background: linear-gradient(180deg, #171717 0%, #0B0B0B 100%);  
}

.absolute_blocks_gradient {
  background: linear-gradient(180deg, rgba(23, 23, 23, 0.5) 0%, rgba(13, 13, 13, 0.5) 100%);  
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.second_absolute_blocks_gradient {
  background: linear-gradient(180deg, rgba(23, 23, 23, 0.3) 0%, rgba(13, 13, 13, 0.3) 100%);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.partners-block-bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.015) 100%);
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);  
}

.level-avtivating-block {
  box-shadow: 0px -10px 40px 0px rgba(0, 0, 0, 0.20);
}

.level-activating-bg {
  background-image: url('../public/images/instrument/activating/bg.png');
  background-size: cover;
  background-repeat: round;
  @media (max-width: 767px) {
    background-image: url('../public/images/instrument/activating/bgMob.png');
    background-size: cover;
	}
  // transform: scale(1);
	// animation: pulse 1s infinite;
}

.level-activating-usdt-bg {
  background-image: url('../public/images/instrument/activatingUsdt/bg.png');
  background-size: cover;
  background-repeat: round;
  @media (max-width: 767px) {
    background-image: url('../public/images/instrument/activatingUsdt/bgMob.png');
    background-size: cover;
	}
  // transform: scale(1);
	// animation: pulse 1s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.995);
	}
	70% {
		transform: scale(1);
	}
  100% {
		transform: scale(0.995);
	}

}

.left_bar_size {
  width: 40%;
  @media (max-width: 1454px) {
    width: 280px;
	}
}

.hideSemiCircleProgressBarText {
  display: none;
}

.activateModalBg {
  background: radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(235, 255, 41, 0.30) 0%, rgba(0, 0, 0, 0.00) 66.39%), linear-gradient(98deg, rgba(40, 40, 40, 0.50) 6.79%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  &_success {
    background: radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(73, 215, 88, 0.30) 0%, rgba(0, 0, 0, 0.00) 66.39%), linear-gradient(98deg, rgba(40, 40, 40, 0.50) 6.79%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  }
  &_error {
    background: radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(236, 51, 106, 0.30) 0%, rgba(0, 0, 0, 0.00) 66.39%), linear-gradient(98deg, rgba(40, 40, 40, 0.50) 6.79%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  }
}

.usdtBgShadow {
  background: radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(80, 175, 149, 0.30) 0%, rgba(0, 0, 0, 0.00) 66.39%), linear-gradient(98deg, rgba(40, 40, 40, 0.50) 6.79%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
}

.yellow_border_color {
  background: linear-gradient(143.92deg, #EBFF29 9.74%, rgba(235, 255, 41, 0.296518) 43.81%, rgba(235, 255, 41, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%),  linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07));
}

.purple_border_color {
  background: linear-gradient(143.92deg, #6850F3 9.74%, rgba(104, 80, 243, 0.296518) 43.81%, rgba(104, 80, 243, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%),
  linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07));  
}

.blue_border_color {
  background: linear-gradient(143.92deg, #1F9BF6 9.74%, rgba(31, 155, 246, 0.296518) 43.81%, rgba(31, 155, 246, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%), linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07));
}

.yellow_circle_bg {
  background: linear-gradient(180deg, #EBFF29 0%, rgba(82, 62, 53, 0) 100%),
  radial-gradient(34.94% 19.21% at 77.95% 86.99%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07));
}

.purple_circle_bg {
  background-color:#6850F3;
}

.blue_circle_bg {
  background: linear-gradient(143.92deg, #1F9BF6 9.74%, rgba(31, 155, 246, 0.296518) 43.81%, rgba(31, 155, 246, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);
}

.upline-block-shadow {
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.35);
}
 
.mobMenu-yellow-shadow {
  box-shadow: 0px 0px 46.153846740722656px 0px rgba(218, 232, 84, 0.15);
}

.mobMenu-gray-shadow {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
}

.user-info-bg {
  background: linear-gradient(98deg, rgba(40, 40, 40, 0.60) 6.79%, rgba(31, 31, 31, 0.30) 100%), rgba(0, 0, 0, 0.50);
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.35);
}

.overview-bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), linear-gradient(97.88deg, rgba(40, 40, 40, 0.5) 6.79%, rgba(31, 31, 31, 0.25) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
}

.level_shadow {
  box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.25);
}

.claiming-bg {
  background: linear-gradient(180deg, rgba(104, 80, 243, 0.00) 0%, #6850F3 100%), #0B0B0B;
  box-shadow: 0px 50px 30px -30px #00000059;
}

.your-farms-bg {
  fill: rgba(217, 217, 217, 0.07);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.35));
}

.scroll-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; 
  &::-webkit-scrollbar {

    display: none;
  }
}
.custom-scroll {
  &::-webkit-scrollbar {
    width: 7.5px;
    background-color: #0E0F10;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #1C1D1E;
  }
  
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #0E0F10;
  }
}

.staking-banner-bg {
  background: linear-gradient(180deg, rgba(104, 80, 243, 0.00) 0.04%, #6850F3 101.13%);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.staking-static-bg {
  background: linear-gradient(90deg, #0B0B0B 9.5%, rgba(11, 11, 11, 0) 52%, #0B0B0B 89%);
  border: 1px solid rgba(104, 80, 243, 0.30);
}

.farms-bg {
  background: linear-gradient(113deg, rgba(40, 40, 40, 0.50) 5.44%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.farms-plus-icon-bg {
  background: linear-gradient(113deg, rgba(40, 40, 40, 0.50) 5.44%, rgba(31, 31, 31, 0.25) 100%);
  box-shadow: drop-shadow(0px 25px 40px rgba(0, 0, 0, 0.05)) drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
}

.staking-modal-bg {
  background: linear-gradient(113deg, rgba(40, 40, 40, 0.50) 5.44%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.user-profit-bg {
  background: linear-gradient(98deg, rgba(40, 40, 40, 0.50) 6.79%, rgba(31, 31, 31, 0.25) 100%), #000;
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.35);
}

.total-activity-bg {
  background: linear-gradient(98deg, rgba(40, 40, 40, 0.60) 6.79%, rgba(31, 31, 31, 0.30) 100%), rgba(0, 0, 0, 0.50);
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.35);
}

.levels-days-bg {
  background: linear-gradient(98deg, rgba(40, 40, 40, 0.60) 6.79%, rgba(31, 31, 31, 0.30) 100%), rgba(0, 0, 0, 0.50);
}

.levels-days-shadow {
  box-shadow: 0px 30px 30px 0px #00000059;
}

.staking-banner-bg {
  background: linear-gradient(98deg, rgba(40, 40, 40, 0.80) 6.79%, rgba(31, 31, 31, 0.40) 100%), rgba(0, 0, 0, 0.50);
}

.border-color-matrix-user {
  &-direct_partner {
    background: linear-gradient(143.92deg, #EBFF29 9.74%, rgba(235, 255, 41, 0.296518) 43.81%, rgba(235, 255, 41, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);
    box-shadow: 0px 25px 40px 0px #0000000D;
    box-shadow: 0px 10px 20px 0px #0000000D;
  }
  &-anotherReceiver {
    background: linear-gradient(143.92deg, #1F9BF6 9.74%, rgba(31, 155, 246, 0.296518) 43.81%, rgba(31, 155, 246, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);    
    box-shadow: 0px 25px 40px 0px #0000000D;
    box-shadow: 0px 10px 20px 0px #0000000D;
  }
  &-recycle {
    background: linear-gradient(143.92deg, #6850F3 9.74%, rgba(104, 80, 243, 0.296518) 43.81%, rgba(104, 80, 243, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);    
    box-shadow: 0px 25px 40px 0px #0000000D;
    box-shadow: 0px 10px 20px 0px #0000000D;
  }
  &-direct {
    background: linear-gradient(143.92deg, #50AF95 9.74%, rgba(80, 175, 149, 0.296518) 43.81%, rgba(80, 175, 149, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);
    box-shadow: 0px 25px 40px 0px #0000000D;
    box-shadow: 0px 10px 20px 0px #0000000D;
  }
  &-overflow {
    background: linear-gradient(143.92deg, #1F9BF6 9.74%, rgba(31, 155, 246, 0.296518) 43.81%, rgba(31, 155, 246, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);
    box-shadow: 0px 25px 40px 0px #0000000D;
    box-shadow: 0px 10px 20px 0px #0000000D;
  }
  &-reinvest {
    background: linear-gradient(143.92deg, #DAE854 9.74%, rgba(218, 232, 84, 0.296518) 43.81%, rgba(218, 232, 84, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);
    box-shadow: 0px 25px 40px 0px #0000000D;
    box-shadow: 0px 10px 20px 0px #0000000D;
  }
  &-gift {
    background: linear-gradient(143.92deg, #57D354 9.74%, rgba(87, 211, 84, 0.296518) 43.81%, rgba(87, 211, 84, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);    
    box-shadow: 0px 25px 40px 0px #0000000D;
    box-shadow: 0px 10px 20px 0px #0000000D;
  }
  &-stored {
    background: linear-gradient(143.92deg, #FF9549 9.74%, rgba(255, 149, 73, 0.296518) 43.81%, rgba(255, 149, 73, 0) 58.17%, rgba(255, 255, 255, 0) 79.09%, #FFFFFF 87.23%);    
    box-shadow: 0px 25px 40px 0px #0000000D;
    box-shadow: 0px 10px 20px 0px #0000000D;
  }
}

.matrix-user-modal-bg {
	&-direct_partner {
    background: radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(235, 255, 41, 0.30) 0%, rgba(0, 0, 0, 0.00) 66.39%), rgba(81, 81, 81, 0.10);
   	} 
	&-anotherReceiver {
		background: radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(31, 155, 246, 0.30) 0%, rgba(0, 0, 0, 0.00) 66.39%), rgba(81, 81, 81, 0.10);
	} 
	&-recycle {
    background: radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(104, 80, 243, 0.30) 0%, rgba(0, 0, 0, 0.00) 66.39%), rgba(81, 81, 81, 0.10);
	} 
  &-direct {
    background: linear-gradient(0deg, rgba(81, 81, 81, 0.1), rgba(81, 81, 81, 0.1)), radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(80, 175, 149, 0.3) 0%, rgba(0, 0, 0, 0) 66.39%);
  }
  &-overflow {
    background: linear-gradient(0deg, rgba(81, 81, 81, 0.1), rgba(81, 81, 81, 0.1)), radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(31, 155, 246, 0.3) 0%, rgba(0, 0, 0, 0) 66.39%);
  }
  &-reinvest {
    background: linear-gradient(0deg, rgba(81, 81, 81, 0.1), rgba(81, 81, 81, 0.1)), radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(218, 232, 84, 0.3) 0%, rgba(0, 0, 0, 0) 66.39%);
  }
  &-gift {
    background: linear-gradient(0deg, rgba(81, 81, 81, 0.1), rgba(81, 81, 81, 0.1)), radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(87, 211, 84, 0.3) 0%, rgba(0, 0, 0, 0) 66.39%);
  }
  &-stored {
    background: linear-gradient(0deg, rgba(81, 81, 81, 0.1), rgba(81, 81, 81, 0.1)), radial-gradient(182.94% 107.94% at 50% -7.94%, rgba(255, 149, 73, 0.3) 0%, rgba(0, 0, 0, 0) 66.39%);
  }
}

button, a {
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    opacity: 0.75;
  }
  &:focus {
    outline: none;
  }
}

.notify-styles {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: linear-gradient(98deg, rgba(40, 40, 40, 0.50) 6.79%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.35);
}


#wcm-modal {
  z-index: 99999 !important;
}

.activity-bg {
  background: linear-gradient(98deg, rgba(40, 40, 40, 0.60) 6.79%, rgba(31, 31, 31, 0.30) 100%), rgba(0, 0, 0, 0.50);
}

.activity-shadow {
  @media (max-width: 1121px) {
    box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.35);
	}
}

.tableBg {
  background: linear-gradient(98deg, rgba(40, 40, 40, 0.60) 6.79%, rgba(31, 31, 31, 0.30) 100%), rgba(0, 0, 0, 0.50);
}

.partners-info-modal-bg {
  background: linear-gradient(113deg, rgba(40, 40, 40, 0.50) 5.44%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.promo-banner-bg {
  background: linear-gradient(113deg, rgba(40, 40, 40, 0.50) 5.44%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.learn-more-button-bg {
  background: var(--0000002-paints, linear-gradient(98deg, rgba(40, 40, 40, 0.60) 6.79%, rgba(31, 31, 31, 0.30) 100%), rgba(0, 0, 0, 0.50));
}

.about-blocks-border {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 81.5%);
}

.about-title {
  background: linear-gradient(92deg, #DEA9FE 36.8%, #472A9F 94.66%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.marketing-levels-bg {
  background: linear-gradient(101deg, rgba(27, 27, 27, 0.70) 0%, rgba(12, 12, 12, 0.70) 100%);
  box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.25)
}

.team-building-matrix-bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
  box-shadow: 0px 27.035px 43.256px 0px rgba(0, 0, 0, 0.10), 0px 10.814px 21.628px 0px rgba(0, 0, 0, 0.05);
}

.staking-table-bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
}

.about-marketcap-text {
  background: linear-gradient(95deg, #4BC9FF 4.27%, #2260FF 109.73%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.no-fees-bg {
  background: linear-gradient(90deg, #AB78FF 5.15%, #5B50DD 109.39%);
}

.no-owner-bg {
  background: linear-gradient(95deg, #A8E2FB 4.27%, #C3C1E7 57.53%, #E3A7F8 109.73%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.price-growth-text {
  background: linear-gradient(93deg, #9BFFA5 6.03%, #22F66A 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.price-growth-bg {
  background: linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 81.5%),
  radial-gradient(118.4% 100% at 50% 0%, rgba(78, 221, 118, 0.3) 0%, rgba(0, 0, 0, 0) 100%); 
}

.holder-text {
  background: linear-gradient(92deg, #FFF 15.75%, rgba(255, 255, 255, 0.15) 122.17%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.smart-contracts-container-bg {
  background: linear-gradient(113deg, rgba(40, 40, 40, 0.50) 5.44%, rgba(31, 31, 31, 0.25) 100%), rgba(0, 0, 0, 0.60);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.instruments-nonactive-bg {
  background: linear-gradient(112.85deg, rgba(40, 40, 40, 0.5) 5.44%, rgba(31, 31, 31, 0.25) 100%);
}

.statics-usdt-bg {
  background: linear-gradient(106deg, #217760 0%, #499F88 100%);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.statics-frgx-bg {
  background: linear-gradient(106.38deg, #4B32D5 0%, #6950F3 100%);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.timer-bg {
  background: linear-gradient(106deg, #249677 0%, #499F88 100%);
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.35);
}

.profit-info-shadow {
  background-image: url('../public/images/dashboard/profitInfo/shadow.png');
  background-size: cover;
  background-repeat: round;
  @media (max-width: 767px) {
    background-image: url('../public/images/dashboard/profitInfo/mobShadow.png');
    background-size: cover;
    background-repeat: round;
	}
}

.ticker {
  background: var(--0000002-paints, linear-gradient(98deg, rgba(40, 40, 40, 0.60) 6.79%, rgba(31, 31, 31, 0.30) 100%), rgba(0, 0, 0, 0.50));
  // background: linear-gradient(90deg, #151515 20.49%, rgba(21, 21, 21, 0.00) 42.82%, rgba(21, 21, 21, 0.00) 68.99%, #151515 96.42%);
}

.output-settings-bg {
  background: rgba(81, 81, 81, 0.10);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05), 0px 25px 40px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(100px);
}

.output-settings-item-bg {
  background: linear-gradient(113deg, rgba(40, 40, 40, 0.50) 5.44%, rgba(31, 31, 31, 0.25) 100%);  
}

.buyBoost-gradient {
  background: linear-gradient(255deg, #B721FF -15.96%, #21D4FD 143.22%);
}